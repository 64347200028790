<script lang="ts" setup>
import type { CrossbrandHomepageNewest } from '@integration-layer/ampliance/schemas/crossbrand-homepage-newest-schema.localized'
import type { CrossbrandItem } from '@integration-layer/ampliance/schemas/crossbrand-item-schema.localized'
import HomepageCardContainer from '@design-system/components/Homepage/HomepageCardContainer.vue'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: CrossbrandHomepageNewest
}>()

const data = await useComponentResponse<CrossbrandHomepageNewest>(props)

const homepageCardContainer = ref<InstanceType<
  typeof HomepageCardContainer
> | null>(null)
const lastCardReached = ref(false)

const seo = data.value?.seo

const cards = computed(() =>
  data.value?.crossbrand?.map(
    card => connectCrossbrandCard(card as unknown as CrossbrandItem).card
  )
)

const editorialCards = computed(() => data.value?.logicWrapper)
const editorialValuesTop = computed(() => data.value?.editorialValuesTop)

const handleScrollButtonClick = () => {
  if (lastCardReached.value) {
    homepageCardContainer.value?.backToTop()
  } else {
    homepageCardContainer.value?.handleScroll('down')
  }
}

const handleLastCardReached = (reached: boolean) => {
  lastCardReached.value = reached
}
if (seo)
  // @ts-ignore
  await useAmplienceSeo({ deliveryId: seo._meta.deliveryId, content: seo })
</script>

<template>
  <div class="slider-container">
    <h1 class="sr-only">{{ $ts('homepage.heading') }}</h1>
    <HomepageCardContainer
      ref="homepageCardContainer"
      :brand-cards="cards"
      :editorial-values-top="editorialValuesTop"
      :editorial-cards="editorialCards"
      @last-card-reached="handleLastCardReached"
    />
    <button
      class="bg-neutral-white text-neutral-black border-primitives-grey-50 fixed bottom-0 left-0 z-10 h-8 w-full items-center border-t portrait:hidden landscape:flex"
      :aria-label="$ts('accessibility.scrollDown')"
      :title="
        lastCardReached
          ? $ts('homepage.backToTop')
          : $ts('accessibility.scrollDown')
      "
      role="button"
      tabindex="0"
      @click="handleScrollButtonClick"
    >
      <div class="absolute left-1/2 z-10 -translate-x-1/2 text-center">
        <div v-if="lastCardReached">{{ $ts('homepage.backToTop') }}</div>
        <DefaultIconsChevronDown v-else aria-hidden="true" />
      </div>
    </button>
  </div>
</template>
