import type { DirectiveBinding } from 'vue'
import type { DirectionType } from './trimmedScroll'

declare global {
  interface HTMLElement {
    _trim_touchStartScrollHandler: (e: TouchEvent) => void
    _trim_touchMoveScrollHandler: (e: TouchEvent) => void
  }
}

export type TrimmedTouchScrollOptions = {
  onScroll: (direction: DirectionType) => void
  container: string
  freeScroll?: boolean
}

export const vTrimmedTouchScroll = {
  mounted: (
    el: HTMLElement,
    binding: DirectiveBinding<TrimmedTouchScrollOptions>
  ) => {
    const { onScroll, container, freeScroll } = binding.value

    let containerElement: HTMLElement | null = null
    try {
      containerElement = window.document.querySelector(container)
      if (!containerElement) {
        throw new Error(`Container element not found: ${container}`)
      }
    } catch (error) {
      console.error(error)
      return
    }

    el._trim_touchStartScrollHandler = (e: TouchEvent) =>
      handleTouchScroll({ e, onScroll, freeScroll: freeScroll ?? false })

    el._trim_touchMoveScrollHandler = (e: TouchEvent) =>
      handleTouchScroll({ e, onScroll, freeScroll: freeScroll ?? false })

    containerElement.addEventListener(
      'touchmove',
      el._trim_touchMoveScrollHandler,
      {
        passive: false,
      }
    )
  },

  updated: (
    el: HTMLElement,
    binding: DirectiveBinding<TrimmedTouchScrollOptions>
  ) => {
    const { container, onScroll, freeScroll } = binding.value

    if (freeScroll !== binding.oldValue?.freeScroll) {
      const containerElement: HTMLElement | null =
        window.document.querySelector(container)

      if (!containerElement) {
        return
      }
      containerElement.removeEventListener(
        'touchmove',
        el._trim_touchMoveScrollHandler
      )

      el._trim_touchMoveScrollHandler = (e: TouchEvent) =>
        handleTouchScroll({ e, onScroll, freeScroll: freeScroll ?? false })

      containerElement.addEventListener(
        'touchmove',
        el._trim_touchMoveScrollHandler,
        {
          passive: false,
        }
      )
    }
  },

  unmounted: (
    el: HTMLElement,
    binding: DirectiveBinding<TrimmedTouchScrollOptions>
  ) => {
    const { container } = binding.value
    const containerElement: HTMLElement | null =
      window.document.querySelector(container)

    if (!containerElement) {
      return
    }

    if (el._trim_touchMoveScrollHandler) {
      containerElement.removeEventListener(
        'touchmove',
        el._trim_touchMoveScrollHandler
      )
    }
  },
}

type TouchScrollOptions = {
  e: TouchEvent
  onScroll: (direction: DirectionType) => void
  freeScroll: boolean
}

const touchState = {
  touches: [] as any[],
  touchStart: null as Touch | null,
  scrollForceThreshold: 5,
  scrollForce: 0,
  currentDirection: null as DirectionType | null,
  scrolled: false,
}

const handleTouchScroll = (props: TouchScrollOptions) => {
  if (props.e.type === 'touchstart') {
    touchState.touches = []
    touchState.touchStart = props.e.touches[0]
    touchState.scrolled = false
  } else if (props.e.type === 'touchmove') {
    touchState.touches.push(props.e.touches[0])
  }

  const direction = getDirection(touchState.touches)

  if (props.freeScroll === true) {
    if (direction) {
      props.onScroll(direction)
    }
    return
  }

  props.e.preventDefault()

  if (direction !== touchState.currentDirection) {
    touchState.currentDirection = direction
    touchState.scrollForce = 1
    touchState.scrolled = false
  } else {
    touchState.scrollForce += 1
  }

  if (
    touchState.scrollForce >= touchState.scrollForceThreshold &&
    !touchState.scrolled
  ) {
    if (direction) {
      props.onScroll(direction)
    }
    touchState.scrollForce = 0
    touchState.scrolled = true
  }
}

const getDirection = (touches: any[]) => {
  const start = touches[touches.length - 2]
  const end = touches[touches.length - 1]

  if (!start || !end) {
    return null
  }

  const deltaY = end.clientY - start.clientY

  return deltaY > 0 ? 'up' : 'down'
}
